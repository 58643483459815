import React from "react"
import Home from "../components/home"
import SEO from "../components/seo"
import Layout from "../components/layout"

const IndexPage = ({location}) => (
  <Layout location={location} isHome={true}>
    <SEO title="Home"></SEO>
    <Home></Home>
  </Layout>
)

export default IndexPage
